<template>
  <hr class="separator " style="margin-top: 1px"/>
  <div>
    <div class="custom-text">
      {{ $t('ProductUpgrade.productUpgrade') }}
    </div>
  </div>

  <el-tabs v-model="activeName" style="margin: 10px;" @tab-change="tabChange">
        <el-tab-pane :label="$t('ProductUpgrade.motor')" name="motor">
            <ProductUpgrade code="motor" :name="motorName" :motorIcon="motorIcon" :loading="motorConnectLoading"
                :connected="motorConnected" :dialogVisible='motorProgress' :percentage="percentage"
                :configPercentage="configPercentage" :infos="motorInfo" :uploadFiles="uploadFiles"
                :connect="connectMotor" :upgrade="upgrade" :uploadSuccess="uploadSuccess" :upgradeCancel="upgradeCancel"
                :cancelLoading="cancelLoading" :lastFirmwareFile='motorLastFirmwareFile'
                :lastConfigFile="motorLastConfigFile" :checkUpgrade="checkUpgrade" :clearSelectFile="clearSelectFile"/>
        </el-tab-pane>
        <el-tab-pane code="display" :label="$t('ProductUpgrade.dashboard')" name="display">
            <ProductUpgrade code="display" name='ProductUpgrade.dashboard' :loading="displayConnectLoading"
                :connected="displayConnected" :percentage="percentage" :configPercentage="configPercentage"
                :infos="displayInfo" :uploadFiles="displayUploadFiles" :dialogVisible='displayProgress'
                :connect="connectDisplay" :upgrade="upgrade" :uploadSuccess="uploadSuccess"
                :upgradeCancel="upgradeCancel" :cancelLoading="cancelLoading"
                :lastFirmwareFile='displayLastFirmwareFile' :lastConfigFile="displayLastConfigFile"
                :checkUpgrade="checkUpgrade" :clearSelectFile="clearSelectFile"/>
        </el-tab-pane>
    </el-tabs>
    <PluginBox :visiable="pluginVisiable" :pluginUrl="pluginUrl" :handleClose="() => pluginVisiable = false" :plugin-status="pluginStatus">
    </PluginBox>
</template>


<script setup>

import { ElMessage} from 'element-plus';
import { onMounted, onUnmounted, ref  } from 'vue';
import ProductUpgrade from '@/components/ProductUpgrade.vue';
import api from './api.js'
import PluginBox from '@/components/PluginBox.vue';
import { compareversions } from '@/utils/common.js'
const pluginVisiable = ref(false)
const pluginUrl = ref('')
import router from "@/router";

import i18n from "@/i18n";
const t = i18n.global.t; // 将 i18n.global.t 方法赋值给变量 t

// 导入国际化文本
const cancelLoading = ref(false)
const displayProgress = ref(false)
const motorProgress = ref(false)
const percentage = ref(0)
const configPercentage = ref(0)
const activeName = ref('motor')
const motorName = ref('ProductUpgrade.motor')
const motorIcon = ref('mid')
const motorInfo = ref([
    { name: 'ProductUpgrade.model', key: 'code', value: '' },
    { name: 'ProductUpgrade.serialNumber', key: 'mcusncode', value: '' },
    { name: 'ProductUpgrade.firmwareVersion', key: 'softwareversion', value: '' },
    { name: 'ProductUpgrade.hardwareVersion', key: 'hardwareversion', value: '' },
    { name: 'ProductUpgrade.parameterVersion', key: 'parameterversion', value: '' },
])

const displayInfo = ref([
    { name: 'ProductUpgrade.model', key: 'code', value: '' },
    { name: 'ProductUpgrade.serialNumber', key: 'mcusncode', value: '' },
    { name: 'ProductUpgrade.firmwareVersion', key: 'softwareversion', value: '' },
    { name: 'ProductUpgrade.hardwareVersion', key: 'hardwareversion', value: '' },
])
const motorConnected = ref(false)
const uploadFiles = ref({
    firmware: {
        name: 'ProductUpgrade.firmwareUpgrade',
        fileUrl: '',
        fileName: '',
        key: '',
        firmwareId: '',
        options: [],
        visible: true
    },
    config: {
        name: 'ProductUpgrade.parameterFileUpgrade',
        fileUrl: '',
        fileName: '',
        key: '',
        firmwareId: '',
        options: [],
        visible: true
    }
})
function resetUploadFiles () {
    uploadFiles.value = {
    firmware: {
        name: 'ProductUpgrade.firmwareUpgrade',
        fileUrl: '',
        fileName: '',
        key: '',
        firmwareId: '',
        options: [],
        visible: true
    },
    config: {
        name: 'ProductUpgrade.parameterFileUpgrade',
        fileUrl: '',
        fileName: '',
        key: '',
        firmwareId: '',
        options: [],
        visible: true
    }
}
}

const motorConnectLoading = ref(false)
const displayConnectLoading = ref(false)
const displayConnected = ref(false)


const displayUploadFiles = ref({
    firmware: {
        name: 'ProductUpgrade.firmwareUpgrade',
        fileUrl: '',
        fileName: '',
        key: '',
        firmwareId: '',
        options: [],
        visible: true
    },
    config: {
        name: 'ProductUpgrade.imageFileUpgrade',
        fileUrl: '',
        fileName: '',
        key: '',
        firmwareId: '',
        options: [],
        visible: false
    }
})

function resetDisplayUploadFiles() {
    displayUploadFiles.value = {
    firmware: {
        name: 'ProductUpgrade.firmwareUpgrade',
        fileUrl: '',
        fileName: '',
        key: '',
        firmwareId: '',
        options: [],
        visible: true
    },
    config: {
        name: 'ProductUpgrade.imageFileUpgrade',
        fileUrl: '',
        fileName: '',
        key: '',
        firmwareId: '',
        options: [],
        visible: false
    }
}
}

let motorFirmwareId = 0;
let motorConfigId = 0;
let displayFirmwareId = 0
let displayconfigId = 0

const motorLastFirmwareFile = ref('')
const motorLastConfigFile = ref('')
const displayLastFirmwareFile = ref('')
const displayLastConfigFile = ref('')

const pluginVersion = ref('')
const pluginStatus = ref(1)



let ws = null
let wsPath

onMounted(() => {
    connectMotor()


})
onUnmounted(() => {
    ws.close()
    ws = null

})



async function connectMotor() {
    motorConnectLoading.value = true
    motorConnected.value = false
    //清空固件列表
    await initWs()
    setTimeout(() => {
        wsSend('closePort')
    }, 200)
    setTimeout(() => {
        connectMotorWs()
    }, 500);
}

async function connectDisplay() {
    displayConnectLoading.value = true
    displayConnected.value = false
    //清空固件列表
    resetDisplayUploadFiles()
    await initWs()
    setTimeout(() => {
        wsSend('closePort')
    }, 200)
    setTimeout(() => {
        connectDisplayWs()
    }, 500);
}

function connectDisplayWs() {
    if (ws.readyState == WebSocket.OPEN) {
        resetDisplayInfo()
        wsSend('getDisplayInfo_boot')
    } else {
        console.error(t('ProductUpgrade.connectionFailure'))
        displayConnectLoading.value = false
    }

}

function initWs() {

    if (ws !== null && ws.readyState === WebSocket.OPEN) return
    ws = new WebSocket('ws://127.0.0.1:1234')
    ws.onopen = () => {
        wsSend('getExeVer')
        setTimeout(() => {
            wsSend('getAppDataPath')
        }, 200);
    }
    ws.onmessage = message => {

        let messageData = JSON.parse(message.data)

        if (messageData.cmd === 'getExeVer') {
            if (messageData.code === 200) {
                pluginVersion.value = messageData.data
                api.pluginVersion().then(res => {
                    if (compareversions(messageData.data, res.version) === -1) {
                        pluginVisiable.value = true
                        pluginUrl.value = res.file_url
                        pluginStatus.value = 2
                        return
                    }
                    api.hardwareProtocol().then(res => {
                        wsSend('updateCanList', { param: res.can })
                        wsSend('updateUartList', { param: res.uart })
                    })

                })
            }
        }

        if (messageData.cmd === 'getMotorInfo_boot') {
            if (messageData.code === 200) {
                ElMessage.success(t('ProductUpgrade.connectionSuccess'))
                var infoData = JSON.parse(messageData.data)
                var productModel
                let params = { "hardware_version-eq": infoData['hardwareversion'] }
                api.productDetail(params).then(res => {
                    if (res.data.length > 0) {
                        productModel = res.data[0]
                        infoData.code = productModel.code
                        if(productModel.motor_type == 0) motorName.value = 'ProductDiagnosis.centerMotor'
                        if(productModel.motor_type == 1) {
                            motorName.value = 'ProductDiagnosis.wheelMotor'
                            motorIcon.value = 'inwheel'
                        }
                    }else{
                        //发送未获取到型号信息
                        api.productNotify({'hardwareVersion':infoData['hardwareversion'],'softwareVersion':infoData['softwareversion']})
                    }
                }).finally(() => {
                    motorInfo.value = motorInfo.value.map(item => {
                        return { ...item, value: infoData[item.key] }
                    })
                    motorConnected.value = true
                    getFirmwareList(0, infoData)
                    getFirmwareList(1, infoData)

                })


            }
            if (messageData.code === 0) {
                console.error(t('ProductUpgrade.motorConnectionFailure'))
                //型号匹配不到返回：This hardware version cannot be recognised,[型号].
                let messages = messageData.message.split(",")
                if(messages.length > 0 && messages[0] === 'This hardware version cannot be recognised'){
                 //发送未获取到型号信息
                 api.productNotify({'hardwareVersion':messages[1],'softwareVersion':messages[2]})
                }
            }
            if (messageData.code === 404) {
                ElMessage.error(t('ProductUpgrade.connectionFailedNoCOMPort'))
            }
            motorConnectLoading.value = false
            api.addConnectRecord({...messageData,type:0,plugin_version:pluginVersion.value})
        }

        if (messageData.cmd === 'getAppDataPath') {
            if (messageData.code === 200) {
                wsPath = messageData.data
            }
        }

        if (messageData.cmd === 'UpdateDeviceProgress') {
            if (messageData.code === 200) {
                var progressData = JSON.parse(messageData.data)

                if (progressData?.filetype === 1) {
                    configPercentage.value = Math.round(progressData?.UpdatedDataCounts / progressData?.UpdateTotalCounts * 100)
                }

                if (progressData?.filetype === 2) {
                    percentage.value = Math.round(progressData?.UpdatedDataCounts / progressData?.UpdateTotalCounts * 100)
                }

            }

        }

        if (messageData.cmd === 'getDisplayInfo_boot') {
            if (messageData.code === 200) {
                displayConnected.value = true
                var displayInfoData = JSON.parse(messageData.data)
                api.productDetail({ "hardware_version-eq": displayInfoData['hardwareversion'] }).then(res => {
                    if (res.data.length > 0) {
                        productModel = res.data[0]
                        displayInfoData.code = productModel.code
                        displayUploadFiles.value.config.visible = (productModel.has_config === 1)
                    }
                }).finally(() => {
                    let displayInfoMap = displayInfo.value
                    displayInfo.value = displayInfoMap.map(item => {
                        return { ...item, value: displayInfoData[item.key] || '' }
                    })
                    getFirmwareList(2, displayInfoData)

                })

            }
            if (messageData.code === 0) {
              console.error(t('ProductUpgrade.meterConnectionFailure'))
            }
            if (messageData.code === 404) {
                ElMessage.error(t('ProductUpgrade.connectionFailedNoCOMPort') )
            }
            displayConnectLoading.value = false
            api.addConnectRecord({...messageData,type:1,plugin_version:pluginVersion.value})
        }

        if (messageData.cmd === 'DeviceLose') {
            ElMessage.error(t('ProductUpgrade.connectionLost'))
            motorConnected.value = false
            displayConnected.value = false
            motorProgress.value = false
            displayProgress.value = false
        }
        if (messageData.cmd === 'UpdateDevice') {
            let updateDeviceData = {
                status: -1,
                message: messageData?.message,
                user_id: localStorage.getItem('userId'),
                organization_id: localStorage.getItem('organizationId'),
                plugin_version: pluginVersion.value,
            }
            if (motorProgress.value) {
                updateDeviceData.type = 0
                updateDeviceData.before_version = motorInfo.value[2].value
                updateDeviceData.firmware_id = motorFirmwareId | motorConfigId
                updateDeviceData.config_id = motorConfigId
                updateDeviceData.firmware_file_name = uploadFiles.value.firmware.fileName
                updateDeviceData.firmware_key = uploadFiles.value.firmware.key
                updateDeviceData.config_file_name = uploadFiles.value.config.fileName
                updateDeviceData.config_key = uploadFiles.value.config.key
                updateDeviceData.model_code = motorInfo.value[0].value
            }
            if (displayProgress.value) {
                updateDeviceData.type = 1
                updateDeviceData.before_version = displayInfo.value[2].value
                updateDeviceData.firmware_id = displayFirmwareId
                updateDeviceData.config_id = displayconfigId
                updateDeviceData.firmware_file_name = displayUploadFiles.value.firmware.fileName
                updateDeviceData.firmware_key = displayUploadFiles.value.firmware.key
                updateDeviceData.config_file_name = displayUploadFiles.value.config.fileName
                updateDeviceData.config_key = displayUploadFiles.value.config.key
                updateDeviceData.model_code = displayInfo.value[0].value

            }

            if (messageData.code === 200) {
                ElMessage.success(t('ProductUpgrade.upgradeSuccess'))
                updateDeviceData.status = 1
                if(messageData.data != undefined){
   
                    updateDeviceData.after_version =  JSON.parse(messageData.data).UpateAfterFirmware 
                    updateDeviceData.spend_second = JSON.parse(messageData.data).TotalTimes 
                    
                }
                if (motorProgress.value) {
                    uploadFiles.value.firmware.fileName = ''
                    uploadFiles.value.config.fileName = ''
                    connectMotor()
                    motorProgress.value = false
                }
                if (displayProgress.value) {
                    displayUploadFiles.value.firmware.fileName = ''
                    displayUploadFiles.value.config.fileName = ''
                    connectDisplay()
                    displayProgress.value = false

                }
            }
            if (messageData.code === 0) {
                updateDeviceData.status = 0
                ElMessage.error(t('ProductUpgrade.upgradeFailure'))
                displayProgress.value = false
                motorProgress.value = false
            }
            api.addUpgradeRecords(updateDeviceData)
            percentage.value = 0
            configPercentage.value = 0
        }
        if (messageData.cmd === 'UpdateDevice_Cancel') {
            if (messageData?.code !== 200) {
                ElMessage.error(messageData?.message || t('ProductUpgrade.cancelUpgradeFailure'))
            }
            cancelLoading.value = false

        }
    }
    ws.onerror = () => {
        motorConnectLoading.value = false
    }
    ws.onclose = () => {
        motorConnected.value = false
        displayConnected.value = false
    }
}

function connectMotorWs() {

    if(ws === null) return
    if (ws.readyState === WebSocket.OPEN) {
        resetMotorInfo()
        wsSend('getMotorInfo_boot')
    } else {
      console.error(t('ProductUpgrade.connectionFailure'))
        motorConnectLoading.value = false
        api.pluginVersion().then(res => {
            pluginVisiable.value = true;
            pluginUrl.value = res.file_url;

        })
    }

}
function resetMotorInfo() {
    motorInfo.value = motorInfo.value.map((item) => {
        item.value = '';
        return item
    })
    resetUploadFiles()
    motorLastFirmwareFile.value = ''
    motorLastConfigFile.value = ''
}

function resetDisplayInfo() {
    displayInfo.value = displayInfo.value.map((item) => {
        item.value = ''
        return item
    })
    resetDisplayUploadFiles()
    displayLastFirmwareFile.value = ''
    displayLastConfigFile.value = ''
}
async function uploadSuccess(res, type, key) {
    if (res.code == -100) {
        localStorage.removeItem('token');
        router.push('/LoginPage');
        return
    }
    if (res.code !== 0) return

    if (type === 'motor') {
        uploadFiles.value[key].fileUrl = wsPath + '\\' + res.fileName
        uploadFiles.value[key].fileName = res.fileName
        uploadFiles.value[key].key = res.key
    }
    if (type === 'display') {
        displayUploadFiles.value[key].fileUrl = wsPath + '\\' + res.fileName
        displayUploadFiles.value[key].fileName = res.fileName
        displayUploadFiles.value[key].key = res.key
    }
    wsSend('downloadfile', {
        p1: res.fileName,
        p2: res.url,
        p3: res.fileSize
    })
}

function upgrade(type) {
    if (type === 'motor') {
        if (ws.readyState == WebSocket.OPEN && motorConnected) {
            //进入boot
            motorFirmwareId = getFirmwareId(uploadFiles.value.firmware)
            motorConfigId = getFirmwareId(uploadFiles.value.config)
            motorProgress.value = true
            uploadFiles.value.firmware.fileUrl = getFirmwareFileUrl(uploadFiles.value.firmware)
            uploadFiles.value.firmware.key = getFirmwareKey(uploadFiles.value.firmware)
            uploadFiles.value.config.fileUrl = getFirmwareFileUrl(uploadFiles.value.config)
            uploadFiles.value.config.key = getFirmwareKey(uploadFiles.value.config)
            setTimeout(() => {
                wsSend('UpdateDevice', {
                    devicetype: 'motor',
                    p1: uploadFiles.value.firmware.fileUrl,
                    p2: uploadFiles.value.config.fileUrl
                })
            }, 1000);

        }
    }
    if (type === 'display') {
        if (ws.readyState == WebSocket.OPEN && displayConnected) {
            displayProgress.value = true
            displayFirmwareId = getFirmwareId(displayUploadFiles.value.firmware)
            displayconfigId = getFirmwareId(displayUploadFiles.value.config)

            displayUploadFiles.value.firmware.fileUrl = getFirmwareFileUrl(displayUploadFiles.value.firmware)
            displayUploadFiles.value.firmware.key = getFirmwareKey(displayUploadFiles.value.firmware)
            displayUploadFiles.value.config.fileUrl = getFirmwareFileUrl(displayUploadFiles.value.config)
            displayUploadFiles.value.config.key = getFirmwareKey(displayUploadFiles.value.config)
            setTimeout(() => {
                wsSend('UpdateDevice', {
                    devicetype: 'dsp',
                    p1: displayUploadFiles.value.firmware.fileUrl,
                    p2: displayUploadFiles.value.config.fileUrl
                })
            }, 1000);

        }
    }
}

function getFirmwareId(config) {
    var fileName = config.fileName
    var options = config.options
    var item = options.filter(option => { return option.fileName === fileName })
    if (item.length === 0) return ''
    return item[0].id
}
function getFirmwareFileUrl(config) {
    var fileName = config.fileName
    if (fileName === undefined) return ''
    var options = config.options
    var item = options.filter(option => { return option.fileName === fileName })
    if (item.length === 0) return config.fileUrl
    return item[0].value
}
function getFirmwareKey(config) {
    var fileName = config.fileName
    var options = config.options
    var item = options.filter(option => { return option.fileName === fileName })
    if (item.length === 0) return config.key
    return item[0].key
}

function wsSend(cmd, param = '') {
    if (!ws) return
    if (ws.readyState !== WebSocket.OPEN) {
        return
    }
    let message = { cmd: cmd, param: param }
    if (param) {
        message = {
            cmd: cmd,
            ...param
        }
    }
    ws.send(JSON.stringify(message))
}

function tabChange(tabName) {
    if (tabName === 'motor' && !motorConnected.value && !motorConnectLoading.value) connectMotor()
    if (tabName === 'display' && !displayConnected.value && !displayConnectLoading.value) connectDisplay()
}

function getFirmwareList(type, infoData) {
    const organizationId = localStorage.getItem('organizationId')
    var params = {
        type: type,
        organization_id: organizationId,
        hardware_version: infoData.hardwareversion,
    }
    if (type === 0) {
        params.firmware_version = infoData.softwareversion.substr(0, 6)
        params.productType = 0
    }
    if (type === 1) {
        params.config_version = infoData.parameterversion
        params.productType = 0
    }
    if (type === 2) {
        params.firmware_version = infoData.softwareversion.substr(0, 6)
        params.productType = 1
    }
    api.firmwareList(params).then(res => {
        if (res?.code !== 0) return
        let options = res?.data.map(item => {
            wsSend('downloadfile', {
                p1: item.file_name,
                p2: item.file_url,
                p3: item.file_size
            })
            return {
                label: item.name,
                value: wsPath + '\\' + item.file_name,
                id: item.id,
                fileName: item.file_name,
                key: item.file
            }
        })
        if (type === 0) { //motor firmware
            uploadFiles.value.firmware.options = options
            if (options.length > 0) motorLastFirmwareFile.value = options[0].fileName
        }
        if (type === 1) { //motor config
            uploadFiles.value.config.options = options
            if (options.length > 0) motorLastConfigFile.value = options[0].fileName
        }
        if (type === 2) { //display firmware
            displayUploadFiles.value.firmware.options = options
            if (options.length > 0) displayLastFirmwareFile.value = options[0].fileName
        }
    })
}

function upgradeCancel() {
    cancelLoading.value = true
    wsSend('UpdateDevice_Cancel', { devicetype: '', p1: '', p2: '' })
}

function checkUpgrade(firmwareFile, configFile, type) {
    if (type === 'motor') {
        uploadFiles.value.firmware.fileName = firmwareFile
        uploadFiles.value.config.fileName = configFile
    }
    if (type === 'display') {
        displayUploadFiles.value.firmware.fileName = firmwareFile
    }
    upgrade(type)


}

function clearSelectFile(code,key){
    if(code === 'motor') {
        uploadFiles.value[key].fileUrl = ''
        uploadFiles.value[key].key = ''
    }
    if(code === 'display') {
        displayUploadFiles.value[key].fileUrl = ''
        displayUploadFiles.value[key].key = ''
    }
}

</script>