<template>
  <hr class="separator " style="margin-top: 1px"/>

  <div>
    <div class="custom-text">
      {{ $t('ProcessAfterSale.processAfterSale') }}
    </div>
    <hr class="separator"/>
  </div>
  <div class="parent">
    <SearchBox @search="handleSearch"/>

    <BulkEditButton @export="handleExport" :queryParameters="searchParams"/>

    <DataTable :tableData="tableData" :loadData="loadData" :showOperation="true" :loading="loading"/>

    <PaginationBar :total-records="totalRecords" :current-page="currentPage" :page-size="pageSize"
                   @page-change="handlePageChange" @page-size-change="handlePageSizeChange"/>
  </div>
</template>

<script>
import SearchBox from './components/SearchBox.vue';
import DataTable from './components/DataTable.vue';
import PaginationBar from '@/components/PaginationBar.vue';
import BulkEditButton from './components/BulkEditButton.vue';
import {getAfterSalePage, getExportData} from "@/api/api";
import dialogMixin from "@/mixins/dialogMixin";

export default {
  mixins: [dialogMixin],
  name: 'ServiceCenter',
  components: {
    SearchBox,
    DataTable,
    PaginationBar,
    BulkEditButton,
  },
  data() {
    return {
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示条数
      totalRecords: 0, // 总记录数
      isSearching: false,
      searchParams: {},
      loading: false, // 加载状态
    };
  },
  created() {
    this.loadData();
  },
  watch : {
    '$i18n.locale'() {
      this.handleSearch(this.searchParams);
    },
  },
  methods: {
    async handleSearch(searchParams) {

      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.isSearching = true;
      this.searchParams = searchParams;
      this.loading = true; // 开始加载
      try {
        //const response = await getAfterSales(page, pageSize, searchParams);
        const response = await getAfterSalePage(page, pageSize, searchParams);
        // 在这里进行数据转换
        this.tableData = response.data.map(item => ({
          after_sales_id: item.after_sales_id,
          taskNumber: item.serial_number,
          customerName: item.customer_name, // 根据值映射中文名称
          country: this.$t(`countries.${item.country_code}`),
          address: item.address,
          brand: item.brand,
          phone: item.phone,
          deliveryAddress: item.pick_up_address,
          email: item.email,
          contact: item.contact,
          progress: this.mapProcessingProgress(item.process_status),
          submissionTime: item.commit_at,
        }));

        this.totalRecords = response.meta.total;
        this.currentPage = response.meta.current_page;
        this.pageSize = parseInt(response.meta.per_page);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loading = false; // 加载结束
      }
    },

    // 处理分页变化事件
    async handlePageChange(newPage) {
      // 异步操作的处理逻辑
      try {
        this.currentPage = newPage;
        // 根据新的页码发送请求获取数据，更新tableData
        if (this.isSearching) {
          await this.handleSearch(this.searchParams);
        } else {
          await this.loadData();
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    // 处理每页显示条数变化事件
    async handlePageSizeChange(newPageSize) {
      // 异步操作的处理逻辑
      try {
        this.pageSize = typeof newPageSize === 'string' ? parseInt(newPageSize, 10) : newPageSize;
        // 根据新的页码发送请求获取数据，更新tableData
        if (this.isSearching) {
          await this.handleSearch(this.searchParams);
        } else {
          await this.loadData();
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    async loadData() {
      this.isSearching = false;
      const page = this.currentPage;
      const pageSize = this.pageSize;
      this.loading = true; // 开始加载
      try {
        //const data = await getAfterSales(page, pageSize);
        const data = await getAfterSalePage(page, pageSize);
        // 将从服务器获取的数据进行字段重命名
        this.tableData = data.data.map(item => ({
          after_sales_id: item.id,
          taskNumber: item.serial_number,
          customerName: item.customer_name, // 根据值映射中文名称
          country: this.$t(`countries.${item.country_code}`),
          country_id: item.country_id,
          address: item.address,
          brand: item.brand,
          phone: item.phone,
          deliveryAddress: item.pick_up_address,
          email: item.email,
          contact: item.contact,
          submissionTime: item.commit_at,
          submitProgress: item.commit_status === 0 ? this.$t('ProcessAfterSale.notSubmitted') : this.$t('ProcessAfterSale.submitted'),
          progress: this.mapProcessingProgress(item.process_status),
        }));
        this.totalRecords = data.meta.total;
        this.currentPage = data.meta.current_page;
        this.pageSize = typeof data.meta.per_page === 'string' ? parseInt(data.meta.per_page, 10) : data.meta.per_page;
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loading = false; // 加载结束
      }
    },

    async handleExport(setExportLoading) {
      setExportLoading(true);
      try {
        await this.exportData();
      } finally {
         setExportLoading(false);
      }
    },

    async exportData() {
      try {
        // 调用导出方法
        const exportedData = await getExportData(this.searchParams);

        if (exportedData.code === -1) {
          this.$message.error(exportedData.message);
          return;
        }
        // 创建一个a标签
        const downloadLink = document.createElement('a');
        // 将获取到的URL作为链接的href属性值
        downloadLink.href = exportedData.url;
        // 设置下载的文件名（可选）
        downloadLink.download = this.$t('ProcessAfterSale.ExportFileName'); // 可以根据实际情况设置文件名
        // 将a标签添加到文档中
        document.body.appendChild(downloadLink);
        // 模拟点击该链接以触发下载
        downloadLink.click();
        // 下载完成后移除该a标签
        document.body.removeChild(downloadLink);
      } catch (error) {
        // 处理错误
        console.error(this.$t('ProcessAfterSale.exportDataFailed'));
      }
    },
  },
};
</script>
<style scoped>
@import "@/assets/css/publicStyle.css";

.custom-text {
  text-align: left;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.separator {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  /* 更透明的横线 */
  margin: 8px 0;
  /* 调整分隔线上下的间距 */
  width: 100%;
  /* 横线占满整个宽度 */
}
</style>